import React from 'react'
import Image from './Image';
import Title from './Title';
import Time from './Time';
import Reporter from './Reporter';
import SubTitle from './SubTitle';
import './Poster.css';
export default function PosterHighl(props) {
    return (
            <div className='poster'>
                <a href={props.link} target="_blank">
                <Image imageSrc={props.imageSrc} />
                <Title posterText={props.posterText} />
                <SubTitle posterTime={props.posterTime} />
                <p className='total-time'>مدت زمان: {props.posterReporter}</p>
                </a>
            </div>
    )
}
