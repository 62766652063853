import React from 'react'

export default function MenuItem(props) {
    return (
    <div className={`menu ${props.isActive ? 'active' : ''}`} onClick={props.onClick}>
        <h2 className='menu1'>{props.title}</h2>
        <svg className={`icon ${props.isActive ? 'active' : ''}`} xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M384-96 0-480l384-384 68 68-316 316 316 316-68 68Z"/></svg>
    </div>
    )
}
