import React from 'react'
import './Sidebar.css';
import MenuItem from './MenuItem';
import { useState } from 'react';
import LiveGames from '../LiveGames';
import TomGames from '../TomGames';
import TodayGames from '../TodayGames';
import Highlights from '../Highlights';

export default function MySideBar() {
    const [activeComponent, setActiveComponent] = useState('LiveGames');

    const renderContent = () => {
        switch (activeComponent) {
            case 'LiveGames':
                return <LiveGames />;
            case 'TodayGames':
                return <TodayGames />;
            case 'TomorrowGames':
                return <TomGames />;
            case 'Highlights':
            default:
                return <Highlights />;
        }
    };

    return (
        <div className='container'>
            <div className='sidebar'>
                <img className='logo' src="./Logo.png"/>
                    <MenuItem title="بازی های زنده" onClick={() => setActiveComponent('LiveGames') } isActive={activeComponent === 'LiveGames'} />
                    <MenuItem title="بازی های امروز" onClick={() => setActiveComponent('TodayGames')} isActive={activeComponent === 'TodayGames'} />
                    <MenuItem title="بازی های فردا" onClick={() => setActiveComponent('TomorrowGames')} isActive={activeComponent === 'TomorrowGames'} />
                    <MenuItem title="خلاصه بازی ها" onClick={() => setActiveComponent('Highlights')} isActive={activeComponent === 'Highlights'} />
            </div>
            <div className='content'>
                {renderContent()}
            </div>
        </div>
    )
}
