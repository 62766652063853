import React, { useEffect, useState } from 'react';
import Poster from './Poster/Poster';

export default function LiveGames() {
    let isEmpty = false;
    const [data, setData] = useState([]);
    useEffect(() => {
        document.body.style.height = "100vh";
        fetch('https://streamhaven.xyz/api/liveGames.php')
            .then(response => response.json())
            .then(jsonData => {
                if (jsonData["result"] === "null") {
                    isEmpty = true;
                    setData([]);
                } else {
                    setData(jsonData);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const renderTags = () => {
        return data.map((item) => (
            <Poster imageSrc={item.thumb} posterText={item.title} posterTime={item.startTime} posterReporter={item.reporter} link={item.link}/>
        ));
        };
    return (
        <>
            {!isEmpty ? renderTags() : <h2 className='noLive'>پخش زنده‌یی نیست فعلا</h2>}
        </>
    )
}
