import React, { useEffect, useState } from 'react';
import Poster from './Poster/Poster';

export default function TomGames() {
    const [data, setData] = useState([]);
    useEffect(() => {
        document.body.style.height = "100vh";
        fetch('https://streamhaven.xyz/api/tommorowMatches.php')
            .then(response => response.json())
            .then(jsonData => setData(jsonData))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const renderTags = () => {
        return data.map((item) => (
            <Poster imageSrc={item.thumb} posterText={item.title} posterTime={item.startTime} posterReporter={item.reporter} />
        ));
        };
    return (
        <>
            {renderTags()}
        </>
    )
}
