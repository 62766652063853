import React from 'react'
import Image from './Image';
import Title from './Title';
import Time from './Time';
import Reporter from './Reporter';
import './Poster.css';
export default function Poster(props) {
    return (
            <div className='poster'>
                <a href={props.link} target="_blank">
                <Image imageSrc={props.imageSrc} />
                <Title posterText={props.posterText} />
                <Time posterTime={props.posterTime} />
                <Reporter posterReporter={props.posterReporter} />
                </a>
            </div>
    )
}
