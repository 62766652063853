import React, { useEffect, useState } from 'react';
import PosterHighl from './Poster/PosterHighl';

export default function TomGames() {
    const [data, setData] = useState([]);
    useEffect(() => {
        document.body.style.height = "100%";
        fetch('https://streamhaven.xyz/api/highlights.php')
            .then(response => response.json())
            .then(jsonData => setData(jsonData))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    const convertSecondsToMinutesAndSeconds = (seconds) => {
        const minutes = Math.floor(seconds / 60); // Get the number of minutes
        const remainingSeconds = seconds % 60;   // Get the remaining seconds
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`; // Return formatted time
    };
    const renderTags = () => {
        return data.map((item) => (
            <PosterHighl imageSrc={item.coverImage} posterText={item.title} posterTime={item.subTitle} posterReporter={convertSecondsToMinutesAndSeconds(item.duration)} link={item.videoLink}/>
        ));
        };
    return (
        <>
            {renderTags()}
        </>
    )
}
